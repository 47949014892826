const isProduction =  process.env.NODE_ENV === 'production';
export const GTM_ID = 'G-M2RVRZ4RLT'; //isProduction ? 'GTM-MDLQS2G' : '';
export const GTM_DEBUG = false

export const GTMPageView = (path) => {

    // @ts-ignore
    window.gtag("event","page_view", { path })
    //ReactGA.send({ hitType: "pageview", page: window.location.pathname, title })
    //ReactGA.pageview(window.location.pathname,undefined,title);
    /*window.gtag('config',window.gtm_id,{
      page_title: title,
      page_location: window.location.pathname
    })*/
};

export const GTMEvent = (event,data) => {
    // @ts-ignore
    window.gtag("event",event,data)
}


export const GTMPageViewOld = (url: string) => {
    interface PageEventProps {
        event: string;
        page: string;
    }

    const pageEvent: PageEventProps = {
        event: 'pageview',
        page: url,
    };
    //@ts-ignore
    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
};

export const GTMTickerNotFound = (ticker: string) => {
    GTMEvent("ticker_not_found",{ tickerNotFound: ticker })
}