import React, { useEffect, useState } from 'react';
//import '../src/index.scss';
/*
To compile index.scss:

npx sass --load-path="node_modules" --source-map-urls=absolute ./src/index.scss ./public/index.css

 */
import '../dist/index.css';
import Router, { useRouter } from 'next/router';

import { configureFontAwesome } from '../src/configureFontAwesome';
import theme from '../src/theme/theme';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { ModalProvider } from '../src/components/common/modal/context/ModalContext';
import IoRootModal from '../src/components/common/modal/IoRootModal';
import Head from 'next/head';
import { GTMPageView } from '../src/analytics/google-tag-manager';
import { appWithTranslation } from 'next-i18next';
import { useAuth, withAuth } from '../src/services/authentication/auth.context';
import { useCookies } from 'react-cookie';
import { setPrefix } from '../src/constants/api-routes.const';
import { HttpService } from '../src/services/api/http.service';
import LoadingComponent from '../src/components/common/LoadingComponent';
import { languages } from '../src/constants/languages';
import { captureClicks } from '../src/analytics/capture-clicks';

function usePageSwitch() {
  const router = useRouter();
  let pathWithoutTrailingSlash = router.asPath.endsWith('/') ? router.asPath.slice(0, -1) : router.asPath;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url) => {
      console.log(
        'handlestart: ',
        url,
        'router.asPath:',
        router.asPath,
        'pathWithoutTrailingSlash:',
        pathWithoutTrailingSlash,
      );
      url !== router.asPath &&
        Object.keys(languages).filter((lan) => url === `/${lan}${pathWithoutTrailingSlash}`).length === 0 &&
        setLoading(true);
    };
    const handleComplete = (url) => {
      console.log(
        'handleComplete: ',
        url,
        'router.asPath:',
        router.asPath,
        'pathWithoutTrailingSlash:',
        pathWithoutTrailingSlash,
      );
      (url === router.asPath ||
        Object.keys(languages).filter((lan) => url === `/${lan}${pathWithoutTrailingSlash}`).length > 0) &&
        setLoading(false);
    };

    const handleError = () => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleError);
    };
  });
  return loading;
}

const MyApp = (props: any) => {
  configureFontAwesome();
  const auth = useAuth();
  const pageSwitching = usePageSwitch();
  const router = useRouter();

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  });

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      GTMPageView(url);
    };
    handleRouteChange(router.asPath);
    Router.events.on('routeChangeComplete', handleRouteChange);

    document.addEventListener('click', captureClicks);

    return () => {
      document.removeEventListener('click', captureClicks);
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  /*
  useEffect(() => {
    auth.login({promptOnFail: false,redirectUri:null})
  },[])*/

  const [refCookies, setRefCookie, removeRefCookie] = useCookies(['ref']);

  useEffect(() => {
    if (router && router.query?.r) {
      setRefCookie('ref', router.query.r);
    }
  }, [router]);

  useEffect(() => {
    if (auth.accessToken && refCookies.ref) {
      console.log('referral:', refCookies.ref);
      fetch(setPrefix('/api/ref'), {
        method: 'PUT',
        headers: HttpService.getHeaders(),
        body: JSON.stringify({ ref: refCookies.ref }),
      }).then((res) => {
        console.log(res);
      });
    }
  }, [refCookies, auth]);

  const { Component, pageProps } = props;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ModalProvider>
            <IoRootModal />
            <div data-ga-locale={router.locale} className={`${pageSwitching ? 'page-switch-overlay' : ''}`}>
              <div className={'page-switch-cover'} hidden={!pageSwitching}>
                <LoadingComponent />
              </div>
              <Component {...pageProps} />
            </div>
          </ModalProvider>
        </ThemeProvider>
      </React.Fragment>
    </>
  );
};
export default appWithTranslation(withAuth(MyApp));
