import React, { useState, ReactElement, useContext } from 'react';
import { ModalContext } from '../context/ModalContext';

export interface IUseModal {
  modalOpen: boolean;
  handleModal?: (content?: React.ReactElement) => void;
  modalContent?: ReactElement;
}

export default function useModal(): IUseModal {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactElement>();

  const handleModal = (content?: React.ReactElement) => {
    setModalOpen(!!content);
    if (content) {
      setModalContent(content);
    }
  };

  return { modalOpen, handleModal, modalContent };
}

export const useModalState = () => {
  const context = useContext(ModalContext);
  return context;
};
