import { GTMEvent } from './google-tag-manager';

export const captureClicks = (ev, el = null, tree = null) => {
  if (el === null) {
    el = ev.target;
  }
  if (tree === null) {
    tree = [];
    tree.data = {};
  }
  /*if (el.dataset.gaTrackThis) {
    tree.trackThis = true;
  }
  if (el.dataset.gaName) {
    tree.push({ name: el.dataset.gaName })
  }*/
  for (const key in el.dataset) {
    if (el.dataset.hasOwnProperty(key)) {
      if (key === 'gaTrackThis') {
        tree.trackThis = true;
        continue;
      }
      if (key === 'gaName') {
        tree.unshift({ name: el.dataset.gaName });
        continue;
      }
      if (key.startsWith('ga') && key !== 'gaTrackThis' && key !== 'gaName') {
        tree.data[key.substring(2, 3).toLowerCase() + key.substring(3)] = el.dataset[key];
      }
    }
  }
  if (el.parentElement === null) {
    if (tree.trackThis) {
      const event = { ...Object.fromEntries(tree.map((el, i) => ['level' + (i + 1), el.name])), ...tree.data };
      GTMEvent('button_click', event);
    } else {
    }
  } else {
    captureClicks(ev, el.parentElement, tree);
  }
};
