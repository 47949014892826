export const languages = {
  en: 'English',
  hu: 'Hungarian',
  no: 'Norwegian',
  da: 'Danish',
  sv: 'Swedish',
  es: 'Spanish',
  de: 'German',
  nl: 'Dutch',
  fr: 'French',
  it: 'Italian',
  fi: 'Finnish',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  cs: 'Czech',
  sk: 'Slovakian',
  af: 'Afrikaans',
  el: 'Greek',
  tr: 'Turkish',
  ru: 'Russian',
};

export const languagesLowercase = {
  en: 'english',
  hu: 'hungarian',
  no: 'norwegian',
  da: 'danish',
  sv: 'swedish',
  es: 'spanish',
  de: 'german',
  nl: 'dutch',
  fr: 'french',
  it: 'italian',
  fi: 'finnish',
  pl: 'polish',
  pt: 'portuguese',
  ro: 'romanian',
  cs: 'czech',
  sk: 'slovakian',
  af: 'afrikaans',
  el: 'greek',
  tr: 'turkish',
  ru: 'russian',
};

export const languageLinks = [
  { label: 'EN', path: '' },
  { label: 'HU', path: '/hu' },
  { label: 'NO', path: '/no' },
  { label: 'SV', path: '/sv' },
  { label: 'DA', path: '/da' },
  { label: 'FI', path: '/fi' },
  { label: 'ES', path: '/es' },
  { label: 'DE', path: '/de' },
  { label: 'NL', path: '/nl' },
  { label: 'FR', path: '/fr' },
  { label: 'IT', path: '/it' },
  { label: 'PT', path: '/pt' },
  { label: 'PL', path: '/pl' },
  { label: 'CS', path: '/cs' },
  { label: 'SK', path: '/sk' },
  { label: 'RO', path: '/ro' },
  { label: 'EL', path: '/el' },
  { label: 'TR', path: '/tr' },
  { label: 'RU', path: '/ru' },
  { label: 'AF', path: '/af' },
];
