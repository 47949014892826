import { authenticationConfig } from './auth0-authentication.config';
import AuthService from './auth0-authentication.service';

function getAuth0AuthenticationService() {
  return AuthService;
}

export default class Auth0AuthenticationServiceLazy {
  static authenticationConfig = authenticationConfig;

  public static async handleRedirectCallback(): Promise<any> {
    const Auth0AuthenticationService = getAuth0AuthenticationService();
    return await Auth0AuthenticationService.auth0.handleRedirectCallback();
  }

  public static async login(): Promise<string> {
    const Auth0AuthenticationService = getAuth0AuthenticationService();
    const accessToken = await Auth0AuthenticationService.auth0.getTokenSilently();
    return accessToken;
  }

  public static async loginWithPrompt(redirectUri?: string) {
    console.log('loginWithPrompt', redirectUri);
    const Auth0AuthenticationService = getAuth0AuthenticationService();
    await Auth0AuthenticationService.auth0.loginWithRedirect({
      prompt: 'login',
      appState: {
        redirectUri: `${encodeURIComponent(redirectUri)}`,
      },
    });
  }

  public static async getUserInfo() {
    const Auth0AuthenticationService = getAuth0AuthenticationService();
    const userInfo = await Auth0AuthenticationService.auth0.getUser();
    return userInfo;
  }

  public static async logout(url?) {
    const Auth0AuthenticationService = getAuth0AuthenticationService();
    await Auth0AuthenticationService.auth0.logout({
      returnTo: url || Auth0AuthenticationService.authenticationConfig.logoutUrl,
    });
  }
}
