import React from 'react';
import { Spinner } from 'reactstrap';

export default function LoadingComponent({ style, className }: { style?: React.CSSProperties; className?: string }) {
  return (
    <div style={style} className={className}>
      <Spinner type="grow" style={{ width: '3rem', height: '3rem', color: '#496ba0' }} />
    </div>
  );
}
