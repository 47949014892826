import {Auth0Client} from '@auth0/auth0-spa-js';
import {authenticationConfig} from "./auth0-authentication.config";

export default class Auth0AuthenticationService {

  static authenticationConfig = authenticationConfig

  public static auth0 : Auth0Client = null

}

try {
  Auth0AuthenticationService.auth0 = new Auth0Client({
    domain: Auth0AuthenticationService.authenticationConfig.domain,
    client_id: Auth0AuthenticationService.authenticationConfig.clientID,
    audience: Auth0AuthenticationService.authenticationConfig.audience,
    redirect_uri: Auth0AuthenticationService.authenticationConfig.redirectUrl,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    scope: 'offline_access profile email openid',
  });
} catch (e) {

}