import React from 'react';
import { Modal } from '@material-ui/core';
import useModal, { useModalState } from './hooks/useModal';

export default function IoRootModal() {
  const rootRef = React.useRef<HTMLDivElement>(null);

  const { modalOpen, modalContent, handleModal } = useModalState();

  return (
    <div ref={rootRef}>
      {modalContent? (
        <Modal
          disablePortal={true}
          disableEnforceFocus={true}
          disableAutoFocus={true}
          className="io-modal-container"
          onClose={() => handleModal!()}
          open={modalOpen && !!modalContent}
          container={() => rootRef.current}
        >
          {modalContent!}
        </Modal>
      ) : null}
    </div>
  );
}
