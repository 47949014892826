import { FetchError } from '../../constants/api-routes.const';

export async function wrapFetch(call: () => Promise<any>) {
  const res = await call();
  if (res.ok) {
    if (res.status === 204) {
      return null;
    }
    if (res.headers.get('content-type')?.includes('application/json')) {
      const body = await res.json();
      return body;
    }
  } else {
    throw new FetchError('Invalid status: ' + res.status, res);
  }
}
