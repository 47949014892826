import { createMuiTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const menuButtonColor = '#0056b3';
export const uncheckedLabelColor = '#101E38';

const theme = createMuiTheme({
  props: {
    MuiButton: {
      disableRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButtonGroup: {
      disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: '#4a6ba0',
      light: '#7b99d1',
      dark: '#144171',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ddb927',
      light: '#ffeb5d',
      dark: '#a78a00',
      contrastText: '#000000',
    },
    background: {
      default: '#E9ECF2',
    },
  },
  typography: {
    fontFamily: [
      'Space Grotesk',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
    fontWeightBold: 'bolder',
  },
  overrides: {
    MuiButtonGroup: {
      groupedHorizontal: {
        textTransform: 'none',
        //border: 'none',
        //color: '#5C6981',
        padding: '12px 24px',
        '&:not(.MuiButton-containedPrimary)': {
          color: uncheckedLabelColor,
          border: 'none',
          fontWeight: 400,
        },
        '&.Mui-disabled': {
          color: '#66748f',
        },
        '&:not(:last-child)': {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
        },
        '&:not(:first-child)': {
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '8px',
        fontSize: '16px',
        lineHeight: '1.5',
        boxShadow: 'none',
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      startIcon: {
        marginLeft: '0',
      },
    },
    MuiTableRow: {
      root: {
        '&.MuiTableRow-hover:hover': {
          backgroundColor: '#F8FAFF',
        },
      },
    },
  },
});

export default theme;

export const radioSelectStyles = makeStyles((theme) => ({
  label: {
    fontSize: '16px',
  },
  radio: {
    padding: '10px',
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
    '&:focus': {
      outline: '2px solid #3f51b5',
      outlineOffset: '2px',
    },
    '&.Mui-focusVisible': {
      color: 'orange',
    },
  },
  uncheckedLabel: {
    color: uncheckedLabelColor,
  },
}));
