import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Auth0AuthenticationServiceLazy from './auth0-authentication.service.lazy';
import { HttpService } from '../api/http.service';
import { useRouter } from 'next/router';
import { useWalletConnect } from './connect-wallet';

const Context = createContext<{
  userInfo: any;
  accessToken: string | null;
  authInProgress: boolean;
  notAuthenticated: boolean;
  error: any;
  login: ({ promptOnFail, redirectUri }: { promptOnFail: boolean; redirectUri: string }) => Promise<void>;
  auth0Login: ({ promptOnFail, redirectUri }: { promptOnFail: boolean; redirectUri: string }) => Promise<void>;
  logout: (url: string) => Promise<void>;
  walletLogin: () => void;
  loggedInViaWallet: boolean;
  walletAccount: any;
  combinedAuthState: 'in-progress' | 'authenticated' | 'not-authenticated';
}>({
  userInfo: null,
  accessToken: null,
  authInProgress: false,
  notAuthenticated: false,
  error: null,
  login: async ({ promptOnFail, redirectUri }) => {},
  auth0Login: async ({ promptOnFail, redirectUri }) => {},
  logout: async (url) => {},
  walletLogin: () => {},
  loggedInViaWallet: false,
  walletAccount: null,
  combinedAuthState: 'in-progress',
});

export const AuthContext = Context;

export function useAuth() {
  return useContext(Context);
}

export function withAuth(WrappedComponent) {
  return (props) => {
    const router = useRouter();
    const {
      loggedInViaWallet,
      account: walletAccount,
      openModal: walletLogin,
      disconnect: logoutWallet,
      walletAuthState,
    } = useWalletConnect();
    const [authState, setAuthState] = useState({
      userInfo: null,
      authInProgress: false,
      notAuthenticated: false,
      accessToken: null,
      error: null,
    });
    const combinedAuthState = useMemo(() => {
      if (
        (!authState.userInfo && !authState.notAuthenticated) ||
        authState.authInProgress ||
        walletAuthState === 'check-in-progress'
      ) {
        return 'in-progress';
      }
      if (authState.userInfo || walletAuthState === 'authenticated') {
        return 'authenticated';
      }
      /*if (walletAuthState === 'not-checked') {
        return 'not-authenticated';
      }*/
      return 'not-authenticated';
    }, [authState.userInfo, authState.notAuthenticated, authState.notAuthenticated, walletAuthState]);

    useEffect(() => {
      /*
      Silently login the user if they are not authenticated and authentication didn't fail before
       */
      if (!authState.userInfo && !authState.notAuthenticated && !authState.authInProgress) {
        auth0Login({ promptOnFail: false });
      }
    }, [authState]);
    const auth0Login = useCallback(
      async ({ promptOnFail, redirectUri }) => {
        try {
          setAuthState({ ...authState, authInProgress: true });
          const accessToken = await Auth0AuthenticationServiceLazy.login();
          HttpService.setGlobalHeader({ Authorization: `Bearer ${accessToken}` });
          const userInfo = await Auth0AuthenticationServiceLazy.getUserInfo();
          setAuthState({ ...authState, authInProgress: false, userInfo, accessToken });
        } catch (e) {
          if (promptOnFail) {
            try {
              await Auth0AuthenticationServiceLazy.loginWithPrompt(redirectUri);
            } catch (e) {
              setAuthState({ ...authState, authInProgress: false, error: e, notAuthenticated: true });
            }
          } else {
            setAuthState({ ...authState, authInProgress: false, error: e, notAuthenticated: true });
          }
        }
      },
      [authState],
    );
    const login = useCallback(
      async ({ promptOnFail, redirectUri }) => {
        // redirect to /auth/login
        await router.push('/auth/login?redirectUri=' + encodeURIComponent(redirectUri));
      },
      [authState],
    );
    const logout = useCallback(
      async (url) => {
        if (loggedInViaWallet) {
          await logoutWallet();
        }
        if (authState?.userInfo) {
          await Auth0AuthenticationServiceLazy.logout(url);
        }
      },
      [authState],
    );

    return (
      <Context.Provider
        value={{
          ...authState,
          auth0Login,
          walletLogin,
          login,
          logout,
          loggedInViaWallet,
          walletAccount,
          combinedAuthState,
        }}
      >
        <WrappedComponent {...props} />
      </Context.Provider>
    );
  };
}
