import { setPrefix } from '../../constants/api-routes.const';

export function getFullUrl(path) {
  let fullUrl = setPrefix(path);
  if (fullUrl.startsWith('/') && typeof window !== 'undefined') {
    // make a valid url from the current location
    fullUrl = window.location.origin + fullUrl;
  }
  return fullUrl;
}

export function getBackendUrlWithParams(path: string, params?: { [key: string]: any }) {
  const url = new URL(getFullUrl(path));
  params && Object.entries(params).forEach((e) => url.searchParams.append(e[0], e[1].toString()));
  return url.toString();
}
