import { hashMessage, recoverAddress } from 'ethers';
import { disconnect as _disconnect, getAccount, signMessage } from '@wagmi/core';
import { useEffect, useState } from 'react';
import { getRabbyKitModal } from './get-rabby-kit.modal';
import { wrapFetch } from '../api/wrap.fetch';
import { getBackendUrlWithParams } from '../api/get-backend-url';

export async function verifySignature(address, signature, message) {
  // Compute the hash of the message
  const messageHash = hashMessage(message);

  // Recover the address from the signature
  const recoveredAddress = recoverAddress(messageHash, signature);

  console.log(
    'address:',
    address,
    'message:',
    message,
    'message hash:',
    messageHash,
    'signature:',
    signature,
    'recovered address:',
    recoveredAddress,
  );

  // Check if the recovered address matches the provided address
  return recoveredAddress.toLowerCase() === address.toLowerCase();
}

export function useWalletConnect() {
  const [rabbyKitModal, setRabbyKitModal] = useState<any>();
  const [account, setAccount] = useState<any>();
  const [accessToken, setAccessToken] = useState<string | null>();
  const [loggedInViaWallet, setLoggedInViaWallet] = useState<boolean>(false);
  const [walletAuthState, setWalletAuthState] = useState<
    'not-checked' | 'check-in-progress' | 'authenticated' | 'not-authenticated'
  >('not-checked');

  useEffect(() => {
    //const modal = getRabbyKitModal()

    const rabbyKit = getRabbyKitModal();

    setRabbyKitModal(rabbyKit);

    setAccount(getAccount());
  }, []);

  function refreshAccount() {
    setAccount(getAccount());
  }

  useEffect(() => {
    if (account?.isConnected && account.address && !loggedInViaWallet) {
      (async () => {
        try {
          setWalletAuthState('check-in-progress');
          const { address } = await wrapFetch(() =>
            fetch(
              getBackendUrlWithParams('/api/auth/wallet/test-login', {
                address: account.address,
              }),
            ),
          );
          if (address === account.address) {
            setLoggedInViaWallet(true);
            setWalletAuthState('authenticated');
          }
        } catch (e) {
          console.error(e);
          console.log('not logged in');
          setWalletAuthState('not-authenticated');
        }
      })();
    }
  }, [account]);

  async function sendLoginRequest() {
    const account = getAccount();
    console.log(account);

    const { nonce, message, token } = await wrapFetch(() =>
      fetch(
        getBackendUrlWithParams('/api/auth/wallet/nonce', {
          address: account.address,
        }),
      ),
    );

    //const message = `Sign this login attempt. Make sure this number matches the one shown in the app: ${nonce}`;

    // Sign the nonce
    const signature = await signMessage({ message: message });
    // include message, nonce, uri

    // Add token and signature into the json body
    const verify = await wrapFetch(() =>
      fetch(
        getBackendUrlWithParams('/api/auth/wallet/verify-login', {
          address: account.address,
        }),
        {
          method: 'POST',
          body: JSON.stringify({ token, signature }),
          headers: { 'Content-Type': 'application/json' },
        },
      ),
    );

    //const verify = await verifySignature(account.address, signature, message);
    console.log('verification successful:', verify);

    // Send signed message to backend
    const loginResponse = { ok: true }; /* await fetch('/api/login', {
          method: 'POST',
          body: JSON.stringify({ address: account.address, signature }),
          headers: { 'Content-Type': 'application/json' }
        }) */

    if (loginResponse.ok) {
      console.log('Login successful');
      setLoggedInViaWallet(true);
      setWalletAuthState('authenticated');
    } else {
      console.error('Login failed');
    }
  }

  async function openModal() {
    if (account?.isConnected && account.address && !loggedInViaWallet) {
      await sendLoginRequest();
      refreshAccount();
    } else {
      if (account?.isConnected) {
        await _disconnect();
        refreshAccount();
      }
      rabbyKitModal.rabbyKit.open({
        onConnect: async () => {
          await sendLoginRequest();
        },
      });
    }
  }

  function closeModal() {
    rabbyKitModal?.rabbyKit?.close();
  }

  async function disconnect() {
    await _disconnect();
    //Clear Login
    await wrapFetch(() => fetch(getBackendUrlWithParams('/api/auth/wallet/clear-login'), { method: 'POST' }));
    setAccount(getAccount());
    setLoggedInViaWallet(false);
  }

  return { account, openModal, closeModal, disconnect, loggedInViaWallet, walletAuthState };
}
