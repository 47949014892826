import { configureChains, createConfig } from '@wagmi/core';
import { arbitrum, bsc, mainnet, polygon } from '@wagmi/core/chains';
import { createModal } from '@rabby-wallet/rabbykit';
import { publicProvider } from '@wagmi/core/providers/public';

export function getRabbyKitModal() {
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [mainnet, arbitrum, bsc, polygon],
    [
      //alchemyProvider({ apiKey: "yourAlchemyApiKey" }),
      //infuraProvider({ apiKey: "yourInfuraApiKey" }),
      publicProvider(),
    ],
  );

  const config = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
  });

  const rabbyKit = createModal({
    chains,
    wagmi: config,
    projectId: 'e368216593efa52d5c7031a2f167a01a',
    appName: 'ioCharts.io Test',
  });

  return { rabbyKit, chains, publicClient, webSocketPublicClient };
}
