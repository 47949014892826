import { IMap } from '../../models/i-map';

export class HttpService {
  private static globalHeaders: IMap<string> = {};

  public static setGlobalHeader(headers: IMap<string>) {
    HttpService.globalHeaders = headers;
  }

  public static getHeaders(headers?) {
    const requestHeaders = headers
      ? { 'Content-Type': 'application/json', ...headers }
      : { 'Content-Type': 'application/json' };
    const thisHeaders = { ...this.globalHeaders, ...requestHeaders };
    return thisHeaders;
  }
}
