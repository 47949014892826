import React from 'react';
import useModal, { IUseModal } from "../hooks/useModal";
import { createContext } from "react";

const ModalContext = createContext<IUseModal>({ modalOpen: false });
const { Provider } = ModalContext;

const ModalProvider = (props: { children: any }) => {
  const modal = useModal();
  
    return (
      <>
         <Provider value={modal}>
            {props.children}
          </Provider>
      </>
   )
};

export { ModalContext, ModalProvider };